.password-container {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  height: 100vh;
  min-height: 700px;
  background-color: #e4e6eb;
  position: relative;
  .container {
    height: 78%;
    .row {
      height: 100%;
      align-items: center;
      justify-content: center;
      .password-card {
        min-height: 520px;
        width: 522px;
        margin: 0 auto;
        background: #ffffff;
        border: 1px solid #9e9e9e;
        box-sizing: border-box;
        border-radius: 1rem;
        transition: 0.3s;
        .password-header {
          border-radius: 1rem 1rem 0 0;
          text-align: center;
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 450;
          font-size: 22px;
          letter-spacing: 0.01em;
          background-color: #ffff;
        }
        .password-close {
          color: #212121;
          position: absolute;
          font-size: 2rem;
          right: 1.1rem;
          top: 0.3rem;
          text-decoration: none;
        }
        .email-text {
          position: absolute;
          width: 474px;
          height: 42px;
          left: 24px;
          top: 104px;
          font-family: "DM Sans";
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 150%;
          color: #3b3b3b;
          opacity: 0.87;
        }
        .email {
          margin-top: 94px;
          input {
            background: #f3f3f3;
            border: 1px solid #ededed;
            border-radius: 2px;
          }
        }
        .password-form-label {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          display: flex;
          align-items: center;
          color: #575757;
        }
        .input-group {
          input {
            background-color: #f3f3f3;
            border-right: none;
            border: #ededed;
          }
        }
        .input-group-append {
          width: 44px;
          .input-group-text {
            border: 1px solid #ededed;
            background: #f3f3f3;
          }
        }
        .forgotten-password {
          position: absolute;
          width: 161px;
          height: 15px;
          top: 11rem;
          left: 337px;
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 110%;
          text-align: right;
          color: #2077b1;
        }

        .password-button {
          width: 484px;
          height: 48px;
          left: 24px;
          top: 254px;
          background: #9e9e9e;
          border-radius: 4px;
          margin-top: 67px;
        }
        .password-enable-button {
          width: 484px;
          height: 48px;
          left: 24px;
          top: 254px;
          background: #2077b1;
          border-radius: 4px;
          margin-top: 67px;
        }
      }
    }
  }
}
