@import "~react-image-gallery/styles/scss/image-gallery.scss";


.outer-profile-container{
    font-family: DM Sans;

        min-height: 100vh;
background-color: white;
.profile-container{
    max-width: 100%;
    .charity-info{
        display: flex;
        justify-content: center;
        width: 100%;
        background: #FFFFFF;
        height:350px;
        .logo{
            // display: block;
            width: 200px;
            height: 200px;
        }
        .heading{
        .title{
            height: 54px;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 150%;
            color: rgba(33, 33, 33, 0.85);
        }
        .location{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
        }
    }
    }

    .info-bar{
        background: #FFFFFF;
        border-top: 1px solid #E5E5E5;
    }
   #info{
    border-bottom: 2px solid #2077B1;
   }
    
    .details{
        margin-top: 0.5rem;
        border-radius: 8px;
    }

    }
}

