.error-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: linear-gradient(
  //     233.48deg,
  //     #11a9df 2.37%,
  //     #13a5dc 21.2%,
  //     #1999d3 39.14%,
  //     #2485c4 57.98%,
  //     #3369b0 76.82%,
  //     #424b9a 92.07%
  //   );
  background-color: #a6a6a6;
  color: #fff;
  text-align: center;

  h3 {
    font-size: 30px;
    font-family: "DM Sans";
  }

  p {
    font-size: 18px;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }
  
}