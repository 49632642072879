
.password-screen {
    height: 100vh;
    width: 100%;
    background-color: #000;
  
    .container {
      height: 100%;
      .row {
        height: 100%;
         align-items: center;
         justify-content: center;
         form {
           height: 160px;
           padding: 1rem;
           background-color: #fff;
           border-radius: 5px;
           box-shadow: 0px 6px 12px #4CAF50;
           button {
             border-radius: 20px;
             box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
           }
         }
      }
    }
    
  }