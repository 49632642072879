.page-not-found-wrapper {
    height: 100vh;
    text-align: center;
    .container {
        height: 100%;
        .row{
            height: 100%;
            align-items: center;
            
            .page-not-found-oops {
                font-family: "DM Sans";
                font-size: 36px;
                color: #3B3B3B;
            }
            .page-not-found-lost {
                color:  #9E9E9E;
                margin-bottom: 2rem;
            }
            .btn{
                width: 182px;
                height: 48px;
            }
        }
    }
}