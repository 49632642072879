input {
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.charity-login-container {
  font-family: DM Sans;
  background-color: #e4e6eb;
  height:100vh;
  min-height:750px;
  position: relative;
  .charitylogin-page-container {
  height: 78%;
    .row {
      height:100%;
      align-items: center;
      justify-content: center;

    }
  }

.login-page-contents{
      .card {
        width: 522px;
        // min-height:505px;
        margin: 0 auto;
        background: #ffffff;
        border: 1px solid #9e9e9e;
        box-sizing: border-box;
        border-radius: 1rem;
        transition: 0.3s;
        
        .header {
          border-radius: 1rem 1rem 0 0;
          text-align: center;
          font-style: normal;
          font-weight: 450;
          font-size: 22px;
          letter-spacing: 0.01em;
          background-color: #ffff;
        }
        .close {
          color: #212121;
          position: absolute;
          font-size: 2rem;
          right: 1.1rem;
          top: 0.3rem;
          text-decoration: none;
        }
        .email-text {
        position: relative;
        height: 24px;
        left: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        }
        .email {
          input {
            background: #f3f3f3;
            border: 1px solid #ededed;
            border-radius: 2px;
            top: 50px;
          }
        }
          .password{
            input{
              background: #f3f3f3;
              border: 1px solid #ededed;
              border-radius: 2px;
            }
        }
        .password-form-label {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          display: flex;
          align-items: center;
          color: #575757;
        }
        .input-group {
          input {
            background-color: #f3f3f3;
            border-right: none;
            border: #ededed;
          }
        }
        .input-group-append {
          width: 44px;
          .input-group-text {
            border: 1px solid #ededed;
            background: #f3f3f3;
          }
        }
        
        .forgotten-password {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 110%;
          text-align: right;
          color: #2077B1;
        }

        .submit-btn {
          // width: 484px;
          // height: 48px;
          // left: 24px;
          // top: 254px;
          background: #9e9e9e;
          border-radius: 4px;
          margin-top: 67px;
        }
        // .password-enable-button {
        //   width: 484px;
        //   height: 48px;
        //   left: 24px;
        //   top: 254px;
        //   background: #2077b1;
        //   border-radius: 4px;
        //   margin-top: 67px;
        // }

        .footer-text{
          margin-top: 1rem;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 150%;
          color: #757575;
          span {
            color: #2077b1;
          }
        }
      }
    }
    }


  .submit-btn{
    background: #2077b1;
          width: 100%;
          height: 48px;
          left: 71px;
          margin-top: 20px;
  }

