.learn-more-nav-wrapper {
    background: #fff;
  
    width: 100%;
  
    .navbar-brand {
      margin-left: 64px;
    }
  
    .navbar-nav {
      margin-right: 64px;
      #learn-more-page-login-button {
        border: 1px solid#2077B1;
        color: #2077b1;
        margin-right: 1rem;
        padding: 0.5rem 1rem;
      }
      #learn-more-page-signup-button {
        background-color: #2077b1;
        color: #fff;
        border-radius: 2px;
        padding: 0.5rem 1rem;
      }
    }
  }
  

  // ================ MOBILE PHONE ==============
@media (max-width: 431px) {
  
    .navbar-brand {
      margin-left: -80px;
      // border: 2px solid black;
      width: 10px;
      height: 50%;
    }
  
    .navbar-nav {
      margin-right: 64px;
      #learn-more-page-login-button {
        border: 1px solid#2077B1;
        color: #2077b1;
        margin-right: 1rem;
        padding: 0.5rem 1rem;
      }
      #learn-more-page-signup-button {
        background-color: #2077b1;
        color: #fff;
        border-radius: 2px;
        padding: 0.5rem 1rem;
      }
    }
}