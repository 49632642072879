.addBalance-content {
  font-family: "DM Sans";
  padding: 1.5rem 0 7rem 0;
  background-color: #f2f2f2;

  .heading{
  .dropdown-container {
    position: relative;
    width: 200px;
    top: -30px;
    margin-right: 20px;
    margin-top: 2rem;
    // margin-left: 77%;
    .dropdown-arrow {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  
    .dropdown-toggle {
      background-color: transparent;
      color: #333;
      padding: 0 10px;
      padding-right: 30px;
      width: 100%;
      text-align: left;
      border: 1px solid #ccc;
      box-shadow: 0px 0px 2px #9e9e9e;
      height: 30px;
  
      &:after {
        display: none;
      }
    }
  
    .dropdown-span {
      width: 160px; 
      display: inline-block;
      // overflow: hidden;
      padding-top: 2px;
    }
    .dropdown-item{
      width: 100%;
      display: inline-block;
    }
  
    .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle, .dropdown-btn .btn-primary:hover, .dropdown-btn  .btn-primary.focus, .dropdown-btn  .btn-primary:focus {
      color: #333;
      background-color: transparent;
      border-color: #ccc;
      box-shadow: 0px 0px 2px #9e9e9e;
    }
  }

}


  .pin-fav {
    border-radius: 0.5rem;
    .card-header {
      background-color: #fff;
      display: flex;
      justify-content: space-between;
    }
    .card-body {
      clear: both;
      height: 120px;
      margin-left: 15px;
      div {
        float: left;
        border-radius: 5px;
        padding: 1rem;
        width: 14rem;
        height: 5rem;
        box-sizing: border-box;
        box-shadow: 0px 0px 2px rgba(32, 119, 177, 0.25);
        .card-text:nth-child(1) {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: #575757;
        }
        .card-text:nth-child(2) {
          font-weight: 500;
          font-size: 24px;
          color: #000;
        }
      }
      // a {
      //   margin-top: 1.6rem;
      //   height: 40px;
      //   width: 120px;
      //   background-color: #2077b1;
      //   float: right;
      //   border-radius: 5px;
      //   color: #fff;
      //   text-align: center;
      //   padding: 7px 0px;
      //   margin-right: 40px;
      // }
      Button {
          margin-top: 1.6rem;
          height: 40px;
          width: 120px;
          background-color: #2077b1;
          float: right;
          border-radius: 5px;
          color: #fff;
          text-align: center;
          padding: 7px 0px;
          margin-right: 40px;
      }
    }
  }
  .transaction-details {
    background-color: #fff;
    border-radius: 0.5rem;
    margin-top: 1rem;
    .row {
      width: 100%;
      margin: 0;
    }
    .transaction-header {
      .transaction {
        padding: 0;
        .nav-tabs {
          .nav-link {
            font-weight: 500;
            padding: 0.5rem 1.5rem;
            color: #757575;
            &.active {
              cursor: default;
              color: #2077b1;
              border-width: 2px;
              border-color: #fff #fff #2077b1;
              span {
                svg {
                  path {
                    fill: #2077b1;
                  }
                }
              }
            }
            svg {
              margin-right: 8px;
            }
          }
        }
      }
    }

    .transaction-body {
      padding: 0 1rem;
      .transaction-content-wrapper {
        border-bottom: 1px solid #c2c2c2;
        padding: 1.5rem 0;
        .transaction-content {
          display: flex;
          justify-content: space-between;
          .transaction-content-column {
            display: flex;
            .img-transaction {
              margin-right: 1rem;
              img {
                width: 32px;
                height: 32px;
              }
            }
          }
          .transaction-amount {
            margin-right: 1.5rem;
          }
        }

        div {
          padding: 0;
          p {
            margin-bottom: 0;
          }
          span {
            font-size: 12px;
            color: #575757;
          }
          .transaction-card-id {
            padding-top: 1rem;
          }
        }
      }
    }
  }
  .transactionButton {
    display: inline-block;
    color: #fff;
    width: 11rem;
    height: 3.5rem;
    background: #2077b1;
    border-radius: 4px;
    line-height: 3.5rem;
    text-align: center;
    margin-top: 0.5rem;
    text-decoration: none;
  }
}

// Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) and (max-width: 992px) {
//   .addBalance-content{
//     .transaction-details{
//       .transaction-body{
//         .transaction-content-wrapper{
//           .transaction-content-column{
//             .row{
//               .img-transaction{

//                 img{
//                   width: 30px;
//                   height: 30px;

//                 }
//               }
//               .transaction-detail-content{
//                 flex: 0 0 89%;
//                 max-width: 89%;
//               }

//             }
//           }
//           .transaction-amount{
//               font-size: 0.9rem;
//           }
//         }
//       }
//     }

//   }

//  }

// Medium devices (tablets, 768px and up)
//@media (min-width: 768px) { }

// Large devices (desktops, 992px and up)
//@media (min-width: 992px) {}

// // Extra large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) { ... }
