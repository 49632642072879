.admin-dropdowns{

.dropdown-container {
    position: relative;
    width: 200px;
    top: -30px;
    margin-right: 20px;
    margin-top: 2rem;
    // margin-left: 77%;
    .dropdown-arrow {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    
  
    .dropdown-toggle {
      background-color: transparent;
      color: #333;
      padding: 0 10px;
      padding-right: 30px;
      width: 100%;
      text-align: left;
      border: 1px solid #ccc;
      box-shadow: 0px 0px 2px #9e9e9e;
      height: 30px;
  
      &:after {
        display: none;
      }
    }
  
    .dropdown-span {
      width: 160px; 
      display: inline-block;
      // overflow: hidden;
      padding-top: 2px;
    }
    .dropdown-item{
      width: 100%;
      display: inline-block;
    }
  
    .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle, .dropdown-btn .btn-primary:hover, .dropdown-btn  .btn-primary.focus, .dropdown-btn  .btn-primary:focus {
      color: #333;
      background-color: transparent;
      border-color: #ccc;
      box-shadow: 0px 0px 2px #9e9e9e;
    }
  }

  .dropdown-charity{
    .dropdown-menu.show{
      display: block;
      max-height: 300px;
      overflow-y: scroll;
    }
  }
  .dropdown-country{
    .dropdown-menu.show{
      display: block;
      max-height: 250px;
      overflow-y: scroll;
    }
  }
  

  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #333 !important;
    background-color: transparent !important;
    border-color: none;
  }

}