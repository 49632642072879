/* Chrome, Safari, Edge, Opera */
input {
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}


.dashboard-wrapper{
  height:100vh;
  background: #f2f2f2;

.dashboard-container {
  padding-top:5rem;
  font-family: DM Sans;
  background: #f2f2f2;

  .welcome {
    height: 36px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(33, 33, 33, 0.85);
  }
  .title {
    top: 5rem;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 150%;
    color: rgba(33, 33, 33, 0.85);
  }

  .fund-widgets {
    // margin-top:1rem;
    .funds-collected-card {
      margin: 0 0.5rem;
      // position: relative;
      width: 33%;
      height: 125px;
      background: #ffffff;
      border-radius: 8px;
      .funds-widget-title {
        position: absolute;
        top: 24px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: capitalize;
      }
      .funds-widget-amount {
        position: absolute;
        top: 49px;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 150%;
        color: rgba(33, 33, 33, 0.85);
      }
    }
    .funds-withdrawn-card {
      margin: 0 0.5rem;
      width: 33%;
      height: 125px;
      background: #ffffff;
      border-radius: 8px;
      .funds-widget-title {
        position: absolute;
        top: 24px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: capitalize;
      }
      .funds-widget-amount {
        position: absolute;
        top: 49px;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 150%;
        color: rgba(33, 33, 33, 0.85);
      }
    }
    .funds-balance-card {
      width: 33%;
      margin: 0 0.5rem;
      background: #ffffff;
      border-radius: 8px;
      .funds-widget-title {
        position: absolute;
        top: 24px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: capitalize;
      }
      .funds-widget-amount {
        position: absolute;
        top: 49px;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 150%;
        color: rgba(33, 33, 33, 0.85);
      }
    }
  }

  .posts-gb {
    margin-top: 1rem;
   
    .posts-gb-widget-title {
      position: absolute;
      left: 119px;
      top: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #3b3b3b;
    }
    .posts-tagged {
      margin: 0 0.5rem;
      position: relative;
      width: 50%;
      height: 125px;
      background: #ffffff;
      border-radius: 8px;
      .posts-gb-count {
        position: absolute;
        left: 119px;
        top: 49px;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 150%;
        color: rgba(33, 33, 33, 0.85);
      }
      .postsTaggedImage {
        display: block;
        margin-top: 2rem;
      }
    }
    .gb-received {
      margin: 0 0.5rem;
      // position: relative;
      width: 50%;
      height: 125px;
      background: #ffffff;
      border-radius: 8px;
      .goldenHeart {
        display: block;
        margin-top: 1.5rem;
      }
      .posts-gb-widget-title {
        position: absolute;
        left: 106px;
        top: 24px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3b3b3b;
      }
      .posts-gb-count {
        position: absolute;
        left: 106px;
        top: 49px;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 150%;
        color: rgba(33, 33, 33, 0.85);
      }
    }
  }

  .live-data{
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;
    .live-data-head{
      height: 40px;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 110%;
      color: rgba(33, 33, 33, 0.85);
    }
    .refresh-button{
      height: 40px;
      width: 120px;
      background-color: #2077b1;
    }
  }

  .fund-raised {


    // .react-datepicker__header {
    //   text-align: center;
    //   background-color: #FFFFFF;
    //   border-bottom: 0px;
    //   border-top-left-radius: 0;
    //   border-top-right-radius: 0;
    //   padding-top: 8px;
    //   position: relative;
    
    // }
    

    // .react-datepicker {
    //   font-family: "Dm Sans";
    //   font-size: 0.8rem;
    //   background-color: #FFFFFF;
    //   color: #000;
    //   border: 0px;
    //   border-radius: 0;
    //   display: inline-block;
    //   position: relative;
    // }


    height: auto;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: 5fr 1fr 0.5fr;
    .date-container {
      display:flex;
      position: relative;
      margin: 0px 0.5rem;
      place-items: center;
      justify-content: space-between;
      font-family: "DM Sans";
      .datePicker{
      // border: 2px solid;
        color: #333;
        margin-left: 0.70rem;
        border-color: #ccc;
        box-shadow: 0px 0px 2px #9e9e9e;
        height: 30px;
        width: 125px;
        position: relative;
        padding-left:10px;
        padding-top:4px;
        input{
          // margin: 4px auto 0px 30px;
          width: 100%;
          border:none;
         
          outline: none;
          position: relative;
        }
        input:hover{
          cursor:pointer;
        }
        
      .dropdown-calIcon {
        position:absolute;
        right: 6px;
        top: 8px;
        border: none;
        outline: none;
      }

      .react-date-picker{
        display: flex;
      }
      .react-date-picker__calendar-button, .react-date-picker__button{
        border:none;
        outline: none;
      }
      .react-date-picker__wrapper{
        border: none;
      }
      .react-calendar{
        font-family: "DM Sans";
        background-color: #ffffff;
        top: 0px;
      }
      .react-date-picker__calendar, .react-date-picker__calendar--open{
        top: 0 !important;
       
      }
      .react-calendar__month-view__weekdays{
        abbr{
        text-decoration: none;

        }
        text-transform: none;
        font-size: 0.85rem;
        font-weight: 500;
        text-decoration: none;
        border: none;
        cursor: text;
      }
    }

      
      .dropdown-toggle {
        background-color: transparent;
        color: #333;
        padding: 0 10px;
        padding-right: 30px;
        width: 100%;
        text-align: left;
        border: 1px solid #ccc;
        box-shadow: 0px 0px 2px #9e9e9e;
        height: 30px;

        &:after {
          display: none;
        }
      }

      .dropdown-span {
        width: 160px;
        display: inline-block;
        padding-top: 2px;
      }
      

      .btn-primary:not(:disabled):not(.disabled).active,
      .btn-primary:not(:disabled):not(.disabled):active,
      .show > .btn-primary.dropdown-toggle,
      .dropdown-btn .btn-primary:hover,
      .dropdown-btn .btn-primary.focus,
      .dropdown-btn .btn-primary:focus {
        color: #333;
        background-color: transparent;
        border-color: #ccc;
        box-shadow: 0px 0px 2px #9e9e9e;
      }
    }


    .go{
      height: 30px;
      margin: auto;
      padding-top: 2px;
      background-color: #2077b1;

      }

    .fund-raised-head {
      height: 40px;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      // line-height: 110%;
      color: rgba(33, 33, 33, 0.85);
    }
    #filter-dropdown {

      background-color: #f2f2f2;
      width: 200px;
      border: 2px solid #e5e5e5;
      margin-right: 2rem;
      color: rgba(0, 0, 0, 0.77);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
    }
    .dropdown-item {
      // padding-left: 40%;
      border-bottom: 1px solid #e5e5e5;
    }
    .filter {
      display: grid;
    }
  }

  .bar {
    width: 100%;
    height: 442px;
    margin-top: 4rem;
    font-size: 1.2rem;
    background-color: white;
  }

  .top-users {
    margin-top: 4rem;
    background: #f2f2f2;
    .top-users-header {
      height: 40px;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 110%;
      color: rgba(33, 33, 33, 0.85);
    }
    .table {
      width: 100%;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 1rem;

      th {
        width: 37px;
        height: 20px;
        top: 21px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        text-align: center;
        color: #575757;
      }
      .avatar {
        background: #eceff1;
        border-radius: 50%;
      }
      td {
        width: 21px;
        height: 24px;
        left: 26px;
        top: 301px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
      }

      .poolAmount {
        color: #34b53a;
      }
    }
  }
}

}
// ================ TABLET ==============


@media (min-width: 577px) and (max-width: 768px) {
  .dashboard-container {
  padding-left: 0rem;
    .welcome {
      top: 3rem;
    }
    .title {
      top: 3rem;
    }
    .fund-widgets {
      margin-top: 5rem;
      .funds-collected-card {
        margin: 0 0.5rem;
        height: 115px;
      }
      .funds-withdrawn-card{
        height: 115px;
      }
      .funds-balance-card {
       top: 2rem;
        height:115px;
      }
    }

    .posts-gb {
      margin-top: 5rem;
      width: 600px;
      // margin-left: 8%;
      .posts-tagged {
        width: 300px;
      }
      .gb-received {
        width: 300px;
      }
    }
    .bar{
      width: 100%;
      padding: auto;
    }
    
  }
 
}
// ================ END TABLET ==============
// ================ LAPTOP & IPAD PRO ==============
@media (min-width: 769px) and (max-width: 1024px) {
  .dashboard-container {
  padding-left: 0rem;
    .fund-widgets {
      .funds-collected-card {
        margin: 0 0.5rem;
        height: 115px;
      }
      .funds-withdrawn-card{
        height: 115px;
      }
      .funds-balance-card {
        height:115px;
      }
    }

    .posts-gb {
      margin-top: 1rem;
    }

  }
}
// ================ END LAPTOP ==============

// ================ LAPTOP ==============
@media (min-width: 1025px) and (max-width: 2000px) {
}

// ================ END LAPTOP ==============

// ================ LARGE SCREENS ==============
@media (min-width: 2000px) {
}

@media screen and (orientation: landscape) and (max-device-width: 823px) {
}
