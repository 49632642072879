@import "~react-image-gallery/styles/scss/image-gallery.scss";



  // --------- Modal ----------- //

  .image-gallery-icon {
    &:hover {
      color: #fff;
    }
  }

  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 20px;
    width: 20px;

    color: #333;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
  }

  .prev-arrow,
  .next-arrow {
    cursor: pointer;
    color: #fff;
    font-size: 20px;
  }

  .prev-arrow {
    position: fixed;
    top: 50%;
    left: 2%;
  }

  .next-arrow {
    position: fixed;
    top: 50%;
    right: 2%;
  }

  .modal {
    // position: fixed;
    overflow: auto;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    background: rgba(63, 61, 61, 0.692);
    // z-index: 2000 !important;

    .modal-main {
      position: absolute;
      width: 680px;
      height: 90%;
      // top: 70%;
      left: 50%;
      transform: translate(-50%, 0%);

      // overflow: scroll;
      // -ms-overflow-style: none; /* IE and Edge */
      // scrollbar-width: none; /* Firefox */

      // &::-webkit-scrollbar {
      //   display: none;
      // }

      .post {
        background: white;
        margin: 7rem 0;
        border-radius: 5px;
        .image-gallery {
          .image-gallery-swipe {
            height: 100%;
          }
        }
        .charity-info-container {
          .charity-info {
            display: flex;
            align-items: center;
            background-color: #eef4f9;
            margin: 30px 0;
            padding: 0px 30px;

            p {
              margin: 0;
            }

            .charity-country {
              font-size: 14px;
            }

            .charity-logo {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
          }

          .comments {
            padding: 0px 30px;
          }
        }
      }
    }
  }

  .modal-close-btn {
    position: fixed;
    top: 3%;
    right: 2%;
    cursor: pointer;
    color: #fff;
    font-size: 25px;
  }

  .most-pooled-item {
    margin-bottom: 20px;

    .most-pooled-item-container {
      display: flex;
      background-color: #fff;
      align-items: center;
      padding: 10px;

      .most-pooled-name {
        font-size: 18px;
        margin: 0;
        margin-left: 10px;
      }
    }

    .most-pooled-profile-pic {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .most-pooled-display-picture {
      width: 100%;
      height: 100%;
    }
  }

  .modal-container {
    padding: 10px;

    .modal-header-container {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .modal-profile-pic {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        -khtml-user-select: none;
        -o-user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
      }

      .modal-name {
        margin: 0;
        margin-left: 10px;
      }

      .post-modal-content {
        margin: 0;
      }
    }
  }

  .Activitypage-card {
    margin-bottom: 1rem;
    border-radius: 8px;
    .activitypage-header {
      background: #ffffff;
      border-radius: 0.5rem 0.5rem 0 0;

      .timeline {
        font-size: 12px;
        color: #757575;
        font-family: "DM Sans";
      }
    }

    .featured-image {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .activitypage-featured-user {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      color: #212121;
    }
    .video-js {
      width: 100%;
    }
    .profile-picture {
      width: 56px;
      height: 56px;
    }
    .Activitypage-user {
      font-family: "DM Sans";
      font-style: normal;
      font-size: 20px;
      font-weight: 500;
      padding-left: 1rem;
      color: #212121;
    }
    .card-text {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #3b3b3b;
      text-align: left;
    }
    .downArrowChat {
      transition: all 0.5s;
      margin-left: 1rem;
    }

    .arrow-animation-up {
      transform: rotate(-180deg);
    }

    .post_info{
      display: flex;
      flex-direction: row;
      margin-left: 0.5rem;
      margin-top: 3%;
      position: relative;
      .sub-text{
        position: static;
        height: 21px;
        font-family: DM Sans;
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
        align-items: center;
        color: #757575;
        margin-right: 0.6rem;
      }
      .pool_amount{
        margin:auto;
        margin-left: 10px;
      }
      .amount_display{
        position: static;
        font-family: DM Sans;
        font-style: normal;
        font-weight: 500;
        color: #3B3B3B;
      }
      #report{
        font-family: DM Sans;
        font-style: normal;
        font-weight: normal;
        text-align: right;
        color: #2077B1;
        margin-right: 1rem;
        flex-grow:2;
        background:none;
        border: none;
        padding : 0;
        cursor: pointer;
        outline: none;
      }
    }
  }

  .slick-arrow {
    background-color: transparent;
  }

  .slick-prev {
    left: 20px;
    z-index: 5;
  }

  .slick-next {
    right: 20px;
  }

  .slick-prev:before,
  .slick-next:before {
    // opacity: 0.7;
    color: #fff;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0;
  }

.image-slider-container {
  position: relative;

  .image-count {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #333;
    width: 45px;
    text-align: center;
    z-index: 1;
    color: #ccc;
    border-radius: 10px;
    font-size: 14px;
  }

  .image-current-count {
    position: relative;
    top: -200px;
    left: 80%;

    background-color: red;
    z-index: 10;
    width: 45px;
  }

  .slick-slide {
    div {
      background-color: #000;
      .video-brand-logo {
        position: absolute;
        top: 11px;
        left: 10px;
        width: 54px;
      }
      .activity-image {
        object-fit: contain;
        max-height: 485px;
      }
      .Activitypage-contentvideo {
        // background-color: black;
        height: 420px;
        width: 100%;
        display: block;
        margin: 0 auto;
        .video-js .vjs-big-play-button {
          font-size: 3em;
          line-height: 2em;
          height: 2em;
          width: 2em;
          display: block;
          position: absolute;
          top: 10px;
          left: 10px;
          padding: 0;
          cursor: pointer;
          opacity: 1;
          border: 0.06666em solid #fff;
          background-color: #2b333f;
          background-color: rgba(43, 51, 63, 0.7);
          border-radius: 6.3em;
          transition: all 0.4s;
        }
      }
    }
  }
}

.spinner-border {
  margin: 0 auto;
}

.charity-activitypage-main {
  // padding: 1rem 5rem 0 5rem;
  // padding-left: 20%;
  
  .heading{
    align-items: center;
    margin: auto;
    height: 80px;
    justify-content: space-between;
  .posts{
    width: 63px;
    height: 36px;
    left: 380px;
    margin-top: 1.5rem;
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: rgba(33, 33, 33, 0.85);
  }
  
  .dropdown-container {
    position: relative;
    width: 200px;
   
    .dropdown-arrow {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  
    .dropdown-toggle {
      background-color: transparent;
      color: #333;
      padding: 0 10px;
      padding-right: 30px;
      width: 100%;
      text-align: left;
      border: 1px solid #ccc;
      box-shadow: 0px 0px 2px #9e9e9e;
      height: 30px;
  
      &:after {
        display: none;
      }
    }
  
    .dropdown-span {
      width: 160px; 
      display: inline-block;
      // overflow: hidden;
      padding-top: 2px;
    }
    .dropdown-item{
      width: 100%;
      display: inline-block;
    }
  
    .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle, .dropdown-btn .btn-primary:hover, .dropdown-btn  .btn-primary.focus, .dropdown-btn  .btn-primary:focus {
      color: #333;
      background-color: transparent;
      border-color: #ccc;
      box-shadow: 0px 0px 2px #9e9e9e;
    }
    
  }
  .dropdown-charity{
    .dropdown-menu.show{
      display: block;
      max-height: 300px;
      overflow-y: scroll;
    }
  }
  .dropdown-country{
    .dropdown-menu.show{
      display: block;
      max-height: 250px;
      overflow-y: scroll;
    }
  }
}
}

.activity-page-charity {
  padding: 0.5rem 1rem;
  width: 100%;
  margin: 1rem 0;
  .activity-page-content {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }
}

.activity-page-charityName {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #575757;
  margin-bottom: 0;
  padding: 0 10px;
}
.activity-page-charityLocation {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #575757;
  margin-bottom: 0;
  padding: 0 10px;
}
.activitypage-featured-picture {
  height: 40px;
  width: 40px;
}
.activitypage-featured-name {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #212121;
}
.rounded-circle {
  border-radius: 50% !important;
  // padding: 10px 20px;
}


// // ================ END LARGE SCREENS ==============

//ipad
@media (min-width: 769px) and (max-width: 1024px) {
}

@media (min-width: 577px) and (max-width: 768px) {

}