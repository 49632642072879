//navbar styles added
.content-navbar-wrapper {
  font-family: "DM Sans";
  font-size: 14px;
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  height: 64px;
  z-index: 2;
  padding: 0 5rem;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);

  .content-navbar {
    padding: 1px 1rem 0 1rem;
    height: 100%;

    .navbar-brand {
      margin-right: 2.5rem;
    }

    .input-group {
      width: 25%;

      .input-group-prepend {
        .input-group-text {
          background-color: #fff;
          border-radius: 1.5rem 0rem 0rem 1.5rem;
          border-right-style: none;
        }
      }
      .form-control {
        height: 48px;
        border-left-style: none;
        border-radius: 0 1.5rem 1.5rem 0;

        &:focus {
          border-color: #ced4da;
          box-shadow: none;
        }
      }
    }
    .navbar-nav {
      height: 100%;

      .nav-item {
        width: 4rem;

        .navbar__link {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          position: relative;

          .tooltiptext {
            position: absolute;
            top: 66px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #eef4f9;
            padding: 0.4rem 1rem;
            border-radius: 5px;
            color: #2077b1;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            white-space: nowrap;
            display: none;
          }

          &:hover {
            background-color: #eef4f9;
            .tooltiptext {
              display: block;
            }
            svg {
              transform: translateY(1px);
              path {
                fill: #2077b1;
              }
            }
            #leaderboard-button {
              transform: translateY(1px);
              rect:nth-child(1) {
                fill: #eef4f9;
              }
              path:nth-child(2) {
                fill: white;
              }
              path:nth-child(3),
              path:nth-child(4),
              path:nth-child(5) {
                fill: white;
              }
            }
            #createPost-button {
              transform: translateY(1px);
              path {
                fill: none;
                stroke: #2077b1;
              }
            }
          }
        }

        .active-button {
          background-color: #eef4f9;

          svg {
            transform: translateY(1px);
            path {
              fill: #2077b1;
            }
          }
          &:after {
            content: "";
            width: 100%;
            border-bottom: 2.2px solid #2077b1;
            border-radius: 4px 4px 0 0;
            position: absolute;
            bottom: 0;
          }
          #leaderboard-button {
            path:nth-child(2) {
              fill: #2077b1;
            }
            path:nth-child(3),
            path:nth-child(4),
            path:nth-child(5) {
              fill: white;
            }
          }
        }
      }
      .create-hover-button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &:hover {
          background-color: #eef4f9;
          cursor: pointer;

          svg {
            transform: translateY(1px);
            path {
              fill: #2077b1;
            }
          }
          #create-post-button {
            transform: translateY(1px);
            path {
              fill: none;
              stroke: #2077b1;
            }
          }
        }
      }

      //Dropdown styles added
      .content-navbar-dropDown {
        .dropdown {
          margin: 0.6rem 0;
          width: 100%;
          #dropdown-menu-align-right {
            border: 1px solid rgba(0, 0, 0, 0.12);
            border-radius: 2rem;
            display: inline-block;
            &:hover {
              background: rgba(32, 119, 177, 0.08);
              border: 1px solid rgba(32, 119, 177, 0.5);
              color: #2077b1;

              .userImage-userProfilePic {
                .contentNavbar-userName {
                  color: #2077b1;
                }
                .contentNavbar-caret {
                  background-color: #2077b1;
                }
              }
            }
            .userImage-userProfilePic {
              display: inline-block;

              .contentNavbar-thumbnail-image {
                width: 26px;
                height: 26px;
                display: inline-block;
                border: 1px solid;
                border-radius: 1rem;
              }
              .contentNavbar-userName {
                color: #575757;
                margin: 0 0.8rem 0 0.5rem;
              }
              .contentNavbar-caret {
                background-color: #c4c4c4;
                border-radius: 0.5rem;
                width: 0.8rem;
                height: 0.8rem;
                line-height: 0.8rem;
                text-align: center;
                display: inline-block;
                margin-left: 2px;
                img {
                  width: 10px;
                  height: 5px;
                }
              }
            }
          }
          .dropdown-toggle::after {
            content: none;
          }
        }
        .showActiveButton {
          #dropdown-menu-align-right {
            background: rgba(32, 119, 177, 0.08);
            border: 1px solid rgba(32, 119, 177, 0.5);
            color: #2077b1;

            .userImage-userProfilePic {
              .contentNavbar-userName {
                color: #2077b1;
              }
              .contentNavbar-caret {
                background-color: #2077b1;
              }
            }
          }
        }
        .dropdown-menu-right {
          box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
          padding: 0;
          width: 13.2rem;

          .navbar__link {
            width: 100%;
            height: 3.5rem;
            line-height: 3rem;
            display: inline-block;
            text-decoration: none;
            color: #575757;
            padding: 0 1rem;

            .dropdown-user-profile-pic {
              margin-left: 0rem;
              vertical-align: middle;
            }
            span {
              margin-left: 1rem;
              font-size: 14px;
            }
          }
          .active-dropdown-button {
            // background-color: #eef4f9;
            color: #2077b1;
          }

          .navbar__link:hover {
            background-color: #eef4f9;
            color: #2077b1;
            transform: translateY(1px);
            svg {
              transform: translateY(0px);
              path {
                fill: #2077b1;
                fill-opacity: 1;
              }
            }
          }

          //Logout button
          .dropdown-item {
            padding: 0;
            height: 3.5rem;
            line-height: 3rem;

            &:hover {
              background-color: #eef4f9;
              color: #2077b1;
              transform: translateY(1px);
              svg {
                transform: translateY(0px);
                path {
                  fill: #2077b1;
                  fill-opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}




// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .content-navbar-wrapper {
    padding: 0 1rem;
    .content-navbar {
      .input-group {
        width: 25%;
        .input-group-prepend {
          .input-group-text {
            padding: 0.3rem;
          }
        }
      }
      .navbar-brand {
        margin-right: 1rem;
      }
      .navbar-nav {
        .nav-item {
          width: 3.3rem;
        }
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .content-navbar-wrapper {
    padding: 0 1rem;
    .content-navbar {
      .navbar-brand {
        margin-right: 1rem;
      }
      .navbar-nav {
        .nav-item {
          width: 3rem;
        }
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .content-navbar-wrapper {
    padding: 0 5rem;
    .content-navbar {
      .navbar-nav {
        .nav-item {
          width: 4rem;
        }
      }
    }
  }
}

// // Extra large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) { ... }
