@mixin responsive-footer {
  .footer-wrapper {
    // background: #6b6b6b;
    // margin-top: 155px;
    height: 156px;
    position: relative;
    // bottom: 0;
    .navbar {
      flex-direction: column;
      justify-content: space-evenly;
      .footer-wrapper-items {
        margin-left: 0;
        a {
          font-size: 16px;
          text-align: center;
        }
        .footerDot {
          display: none;
        }
        .footerPipeline {
          display: inline-block;
        }
      }
      .footer-wrapper-copyright {
        margin-right: 0;
        margin-left: 0 !important;
        font-size: 16px;
      }
    }
  }
}

.charity-footer-wrapper {
  position: absolute;
  background: #fff;
  height: 80px;
  color: #212121;
  bottom: 0;
  width: 100%;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  .navbar {
    height: 100%;
    .footer-wrapper-items {
      display: flex;
      align-items: center;
      margin-left: 10.3%;
      .nav-link {
        color: #212121;
      }
    }
    .footer-wrapper-copyright-charity {
      // position: absolute;
      width: 292px;
      height: 24px;
      left: 574px;
      bottom: 0px;

      /* Body/B3- 16 */

      font-family: DM Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 24px */

      display: flex;
      align-items: center;
      text-align: right;
    }
  }
}

// @media (min-width: 769px) and (max-width: 1024px) {

// }

@import "../custom.scss";
@import "../Login/loginPage.scss";
.footer-wrapper {
  background: #fff;
  height: 64px;
  color: #212121;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  // box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
  .navbar {
    height: 100%;
    .footer-wrapper-items {
      display: flex;
      align-items: center;
      margin-left: 2%;
      .nav-link {
        color: #212121;
      }
    }
    .footer-wrapper-copyright {
      margin-right: 2%;
    }
  }
}
#contactModal {
  .modal-header {
    border-bottom: none;
  }
  .modal-title {
    height: 10rem;
    text-align: center;
    line-height: 37px;
  }
  .modal-body {
    height: 11rem;
  }
}

@media (max-width: $breakpoint-mobile) {
  .footer-wrapper {
    // background: #6b6b6b;
    // margin-top: 155px;
    height: 156px;
    position: relative;
    // bottom: 0;
    .navbar {
      flex-direction: column;
      justify-content: space-evenly;
      .footer-wrapper-items {
        margin-left: 0;
        flex-wrap: wrap;
        a {
          font-size: 16px;
          text-align: center;
          width: 33%;
        }
        .footerDot {
          display: none;
        }
        .footerPipeline {
          display: inline-block;
        }
      }
      .footer-wrapper-copyright {
        margin-right: 0;
        margin-left: 0 !important;
        font-size: 16px;
      }
    }
  }
}
@media (min-width: 432px) and (max-width: $breakpoint-tablet) {
  @include responsive-footer;
}

@media screen and (orientation: landscape) and (min-device-width: 900px) and (max-width: $breakpoint-laptop) {
  .footer-wrapper {
    .navbar {
      .footer-wrapper-items {
        margin-left: 4rem;
      }
      .footer-wrapper-copyright {
        margin-right: 5rem;
      }
    }
  }
}

@media screen and (orientation: landscape) and (min-width: $breakpoint-tablet) and (max-device-width: 900px) {
  .footer-wrapper {
    .navbar {
      font-size: 14px;
      .footer-wrapper-items {
        margin-left: 3rem;
      }
      .footer-wrapper-copyright {
        margin-right: 3rem;
      }
    }
  }
}
