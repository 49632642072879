.stripePayment-content {
  font-family: "DM Sans";
  // padding: 5.5rem 0;
  background-color: #f2f2f2;
  height: 95vh;
  .back-link {
    margin: 1rem 0;
    a {
      color: #2077b1;
    }
  }
  .stripe-your-pool-page {
    background-color: #fff;

    p {
      margin: 0;
      font-size: 24px;
      text-align: center;
    }
    .stripe-header {
      width: 100%;
      margin: 0;
      padding: 1rem 0;
      border-bottom: 1px solid rgba(194, 194, 194, 0.38);
    }
    .stripe-body {
      width: 100%;
      margin: 0;
      padding: 3rem 0;

      // label:nth-of-type(1), label:nth-of-type(2){
      //     color: #575757;
      // }

      .input-group {
        margin-bottom: 2rem;
        .input-group-prepend span {
          width: 56px;
          padding: 0rem 1.4rem;
        }
        .form-control {
          height: 3.5rem;
          z-index: 0;
        }
      }

      .stripe-payment-details {
        .stripe-content-style {
          .stripe-card-element {
            background: white;
            height: 3.2rem;
            padding: 1rem;
            border-radius: 5px;
            border: 1px solid #c2c2c2;
          }
        }
        .stripe-card-expiry-date {
          margin-right: 1rem;
          .stripe-expiry-element {
            height: 3.2rem;
            padding: 1rem;
            border-radius: 5px;
            border: 1px solid #c2c2c2;
          }
        }
        .stripe-card-expiry-date,
        .stripe-card-cvv {
          width: 47%;
          display: inline-block;
          .stripe-card-cvv-element {
            height: 3.2rem;
            padding: 1rem;
            border-radius: 5px;
            border: 1px solid #c2c2c2;
            .ElementsApp {
              .InputElement {
                color: red !important;
              }
            }
          }
        }
      }
      .blue-buttonActive {
        width: 9.7rem;
      }
      .cancel-button {
        width: 5rem;
        height: 3rem;
        border: 1px solid #2077b1;
        border-radius: 0.3rem;
        color: #2077b1;
        text-decoration: none;
        line-height: 3rem;
        text-align: center;
        display: inline-block;
        margin-left: 1rem;
        // &:hover {
        // color: #fff;
        // }
      }
    }
  }
}

// .confirmation-modal-container {
//   .modal-dialog {
//     .modal-content {
//       min-height: 100%;
//       .modal-footer {
//         border-top: 1px solid #fff;
//       }
//     }
//   }
// }

.message-modal-container {
  text-align: center;
  .modal-dialog {
    .modal-content {
      min-height: 63%;
      .modal-header {
        border-bottom: 1px solid #fff;

        .btn-primary {
          background-color: #fff;
          border-color: #fff;
        }
        &:focus {
          color: #000;
          box-shadow: none;
        }
      }
      img {
        width: 321px;
        display: inline-block;
        margin: 0 auto;
      }
      .confirm-message {
        position: absolute;
        bottom: 0;
        width: 100%;
        .modal-title {
          margin-bottom: 1rem;
        }
        .modal-footer {
          border-top: 1px solid #fff;
          display: block;
          .btn {
            width: 360px;
          }
        }
      }
    }
  }
}
// Extra large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) { ... }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}
