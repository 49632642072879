.user-contact-wrapper {
  height: 100vh;
  min-height: 700px;
  background-color: #e4e6eb;
  position: relative;
  .container {
    height: 78%;
    .row {
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
}
