.profile-edit-card {
    // margin-bottom: 1rem;
    .card-container{
        background-color: white;
    }
    .form-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .heading {
        display: flex;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;
      color: rgba(33, 33, 33, 0.85);
      background-color: white;
      justify-content: center;
    }
    
    .profile-edit-text {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: #2077b1;
    }
    .profile-edit-label {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #575757;
    }
    // .profile-edit-message {
    //   color: red;
    //   text-align: center;
    // }
    .profile-edit-savebtn {
      width: 100%;
      height: 48px;
      background: #2077b1;
      border-radius: 4px;
    }
    .dropdown-button {
      background-color: #f3f3f3;

      button {
        background-color: #fff;
        color: #757575;
        border: 1px solid #ededed;
        text-align: left;
        width: 100%;
        display: flex;
        justify-content: space-between;
        &:active {
          background-color: #fff;
          color: #757575;
          border: 1px solid #ededed;
        }
      }
      .dropdown-toggle::after {
        // margin-left: 17rem;
        align-self: center;
      }
    }
  }