.header {
  position: sticky;
  // position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}


// @media (min-width: 577px) and (max-width: 768px) {
  
// }