/* Chrome, Safari, Edge, Opera */
input {
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.login-page {
  font-family: "DM Sans";
  height: 100vh;
  background: #e4e6eb;
  position: relative;
  .login-page-container {
    height: 78%;
    .row {
      height: 100%;
      align-items: center;
      justify-content: center;
      // .login-page-content-wrapper {
      //   border: 1px solid #9e9e9e;
      //   padding: 1.5rem;
      // }
    }
  }
}

.login-page-right-content {
  .card {
    border: 1px solid #9e9e9e;
    border-radius: 1rem;
    width: 522px;
    min-height: 520px;
    margin: 0 auto;
    .card-header {
      background-color: #fff;
      text-align: center;
      position: relative;
      border-radius: 1rem 1rem 0 0;
      .login-page-gopoolit {
        display: inline-block;
        margin-bottom: 0rem;
      }
      .login-close {
        color: #212121;
        position: absolute;
        font-size: 2rem;
        right: 1.1rem;
        top: 0.3rem;
        text-decoration: none;
      }
    }
    .card-body {
      color: rgba(0, 0, 0, 0.54);
      text-align: center;

      //nexo-otp-screen
      .nexmo-otp-screen {
        div {
          div {
            div {
              input {
                width: 4rem !important;
                background: #f3f3f3;
                border: 1px solid #e6e6e6;
                box-sizing: border-box;
                border-radius: 2px;
                height: 56px;
                margin: 0 4px;
              }
            }
          }
        }
        .error-otp-input {
          div {
            div {
              input {
                width: 4rem !important;
                border: 1px solid #e83d3d !important;
                box-shadow: none !important;
                box-sizing: border-box;
                border-radius: 2px;
                height: 56px;
                margin: 0 4px;
              }
            }
          }
        }

        .nexmo-resend-otp-text {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          /* identical to box height, or 24px */

          text-decoration-line: underline;

          color: #2077b1;
          cursor: pointer;
        }
        .disabled {
          color: gray;
          pointer-events: none;
        }

        .nexmo-otp-continue-btn {
          width: 100%;
        }
      }
      //end nexmo-otp-screen
      //form in login password
      .form-group {
        .input-group {
          height: 56px;
          background: #f3f3f3;
          border: 1px solid #ededed;
          border-radius: 2px;
          input {
            height: 100%;
            border: none;
            background: none;
          }
          .input-group-append {
            width: 44px;
            .input-group-text {
              // width: 44px;
              border: none;
              background: none;
            }
          }
        }
        p {
          text-align: right;
          a {
            color: #2077b1;
          }
        }
      }
      //end form in login password

      .login-page-social-media {
        display: flex;
        justify-content: space-between;
      }
      .login-page-or-content {
        hr {
          width: 45%;
          display: inline-block;
          vertical-align: middle;
          border-top: 1px solid #9e9e9e;
        }
        span {
          display: inline-block;
          margin: 0 10px;
        }
        margin: 1rem 0;
      }

      .nexmo-login {
        .country-mobile-number {
          background-color: #f3f3f3;
          border-radius: 2px;

          .parentFlagToggleButton {
            position: relative;
            border: 1px solid #f3f3f3;
            height: 48px;
            display: inline-block;
            .img-fluid {
              position: absolute;
              top: 22px;
              right: 10px;
            }
            .flagButton {
              button {
                color: #495057;
                width: 100%;
                height: 47px;
                background-color: transparent;
                border-color: #f3f3f3;
                &:after {
                  opacity: 0;
                }
              }
              .btn-primary:active {
                background-color: transparent;
                border-color: transparent;
              }
            }

            .show {
              .btn-primary.dropdown-toggle {
                background-color: transparent;
                border-color: transparent;
              }
            }
            .dropdown-menu {
              .flagList {
                width: 230px;
                height: 120px;
                overflow: scroll;
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
                margin-top: 20px;
                &::-webkit-scrollbar {
                  display: none;
                }
              }
            }
          }
          .dropdown-button {
            display: inline-block;
            width: 22%;
            border-radius: 0;
            border: none;
            border-right: 1px solid #e6e6e6;
            background: transparent;
            color: rgba(0, 0, 0, 0.54);
          }

          .nexmo-input-mobile {
            display: inline-block;
            width: 75%;
            height: 3.5rem;
            border: none;
            background: transparent;
          }
        }
        .btn {
          width: 100%;
          height: 48px;
        }
        .nexmo-OTP-button {
          margin-right: 1rem;
        }
        .nexmo-verify-button {
          background-color: #9e9e9e;
        }
      }
    }
    .card-footer {
      background-color: #fff;
      border: none;
      border-radius: 1rem;
    }
  }

  .login-page-terms-condition-footer {
    color: rgba(0, 0, 0, 0.66);
    opacity: 0.87;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin: 0rem;
    span {
      color: #2077b1;
    }
  }
}
