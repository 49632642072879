.copyurl-toast {
  width: 370px;
  height: 60px;

  // background: linear-gradient(0deg, rgba(50, 193, 116, 0.16), rgba(50, 193, 116, 0.16)), #FFFFFF;
  border: #eef4f9;
  box-sizing: border-box;
  border-radius: 4px;
  position: fixed;
  bottom: 0;
  z-index: 1;
  left: 29px;
  background: white;
  line-height: 50px;
  padding: 0 1rem;
  .copyurl-toast-text {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: black;
    margin-left: 0.5rem;
  }
}
.sharingOnMedia-main {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  .sharingOnMedia-menu {
    padding: 0;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  }

  .dropdown-item {
    width: 100%;
    height: 3rem;
    line-height: 2.7rem;
    display: inline-block;
    padding: 0;
    color: #575757;
    font-weight: 500;
    font-size: 14px;
    .react-share__ShareButton {
      height: 100%;
      width: 100%;
      text-align: left;
      padding: 0 1rem !important;
      img {
        width: 24px;
      }
    }
    span {
      margin-left: 1rem;
    }

    &:hover {
      background-color: #eef4f9;
      color: #2077b1;
      transform: translateY(1px);
      .social-media-icon {
        path {
          fill: #2077b1;
        }
      }
    }
  }
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 1rem 0.75rem;
  color: #6c757d;
  /* background-color: rgba(255, 255, 255, 0.85); */
  background-clip: padding-box;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.05); */
}

.comments-share-button {
  border: none;
  width: 49%;
  height: 40px;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  svg {
    margin-right: 0.5rem;
  }
  &:hover {
    color: #2077b1;
    background: linear-gradient(
        0deg,
        rgba(32, 119, 177, 0.08),
        rgba(32, 119, 177, 0.08)
      ),
      #ffffff;
    svg {
      path {
        fill: #2077b1;
      }
    }
  }
  &:focus {
    outline: none;
  }
}
#reportModal {
  .modal-content {
    border-radius: 1rem;
  }
}
#shareModal {
  font-family: "DM Sans";
  overflow: hidden;
  .modal-content {
    // min-height: 580px;
    .modal-body {
      .scroll-menu {
        // overflow: auto;
        // white-space: nowrap;
        // width: 400px;
        .share-content {
          // display: inline-block;
          margin: 0 1rem;
          cursor: pointer;
          span {
            display: inline-block;
            svg,
            span {
              margin: 0 auto;
              display: block;
            }
          }
          button {
            display: inline-block;
            img {
              width: 30px;
              height: 30px;
            }
            img,
            span {
              margin: 0 auto;
              display: block;
            }
          }
        }
        scrollbar-width: none; //Firefox
        .slick-prev {
          z-index: 1;
          left: -15px;
        }
        .slick-next {
          right: 10px;
          z-index: 1;
        }
        .slick-prev.slick-disabled:before,
        .slick-next.slick-disabled:before {
          opacity: 0;
        }
        .slick-prev:before {
          font-size: 30px;
          line-height: 0;
          color: transparent;
          background-image: url("../../svg/rightArrow.svg");
        }
        .slick-next:before {
          font-size: 30px;
          line-height: 0;
          color: transparent;
          background-image: url("../../svg/leftArrow.svg");
        }
      }
    }
  }
}


