.charities-adminreport {
    background: #f2f2f2;
    .charities-table-header {
      height: 40px;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 110%;
      color: rgba(33, 33, 33, 0.85);
    }
    
  }
  .table {
    width: 100%;
    background: #ffffff;
    border-radius: 8px;
    margin-top: 1rem;

    th {
      width: 37px;
      height: 20px;
      top: 21px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      text-align: center;
      color: #575757;
    }
    .avatar {
      background: #eceff1;
      border-radius: 50%;
    }
    td {
      width: 21px;
      height: 24px;
      left: 26px;
      top: 301px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
    }

    .poolAmount {
      color: #34b53a;
    }
  }
  .nav-admin-reports-active {
     border-bottom: 2.2px solid #2077b1;
         
  }

  .admin-dashboard-navbar{

    font-family: "DM Sans";
    font-size: 14px;
    background-color: #fff;
    position: sticky;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    padding-left: 6rem;

    .dashboard-navbar-elements{
      display:inline-block;
      cursor:pointer;
      padding:1rem 1rem 0.8rem 1rem;
    }
  }

  .admin-dashboard-navbar-contents{
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    padding-left: 12rem;
  }

  .date-container-charityList {
    display:flex;
    position: relative;
    margin: 0px 0.5rem;
    place-items: center;
    justify-content: space-between;
    font-family: "DM Sans";
    .datePicker{
    // border: 2px solid;
      color: #333;
      margin-left: 0.70rem;
      border-color: #ccc;
      box-shadow: 0px 0px 2px #9e9e9e;
      height: 30px;
      width: 125px;
      position: relative;
      padding-left:10px;
      padding-top:4px;
      input{
        // margin: 4px auto 0px 30px;
        width: 100%;
        border:none;
       
        outline: none;
        position: relative;
      }
      input:hover{
        cursor:pointer;
      }
      
    .dropdown-calIcon {
      position:absolute;
      right: 6px;
      top: 8px;
      border: none;
      outline: none;
    }

    .react-date-picker{
      display: flex;
    }
    .react-date-picker__calendar-button, .react-date-picker__button{
      border:none;
      outline: none;
    }
    .react-date-picker__wrapper{
      border: none;
    }
    .react-calendar{
      font-family: "DM Sans";
      background-color: #ffffff;
      top: 0px;
    }
    .react-date-picker__calendar, .react-date-picker__calendar--open{
      top: 0 !important;
     
    }
    .react-calendar__month-view__weekdays{
      abbr{
      text-decoration: none;

      }
      text-transform: none;
      font-size: 0.85rem;
      font-weight: 500;
      text-decoration: none;
      border: none;
      cursor: text;
    }
  }

    
    .dropdown-toggle {
      background-color: transparent;
      color: #333;
      padding: 0 10px;
      padding-right: 30px;
      width: 100%;
      text-align: left;
      border: 1px solid #ccc;
      box-shadow: 0px 0px 2px #9e9e9e;
      height: 30px;

      &:after {
        display: none;
      }
    }

    .dropdown-span {
      width: 160px;
      display: inline-block;
      padding-top: 2px;
    }
    

    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show > .btn-primary.dropdown-toggle,
    .dropdown-btn .btn-primary:hover,
    .dropdown-btn .btn-primary.focus,
    .dropdown-btn .btn-primary:focus {
      color: #333;
      background-color: transparent;
      border-color: #ccc;
      box-shadow: 0px 0px 2px #9e9e9e;
    }

    .go{
      height: 30px;
      margin: auto;
      padding-top: 2px;
      background-color: #2077b1;

      }
  }


  .admin-dropdowns-country-charity{
    display: flex;
    margin-left: 1rem;
    .dropdown-container {
        position: relative;
        .dropdown-arrow {
          position: absolute;
          top: 10px;
          right: 10px;
        }
        
      
        .dropdown-toggle {
          background-color: transparent;
          color: #333;
          padding: 0 10px;
          padding-right: 30px;
          width: 100%;
          text-align: left;
          border: 1px solid #ccc;
          box-shadow: 0px 0px 2px #9e9e9e;
          height: 30px;
      
          &:after {
            display: none;
          }
        }
      
        .dropdown-span {
          width: 160px; 
          display: inline-block;
          // overflow: hidden;
          padding-top: 2px;
        }
        .dropdown-item{
          width: 100%;
          display: inline-block;
        }
      
        .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle, .dropdown-btn .btn-primary:hover, .dropdown-btn  .btn-primary.focus, .dropdown-btn  .btn-primary:focus {
          color: #333;
          background-color: transparent;
          border-color: #ccc;
          box-shadow: 0px 0px 2px #9e9e9e;
        }
      }
    
      .dropdown-charity{
        .dropdown-menu.show{
          display: block;
          max-height: 300px;
          overflow-y: scroll;
        }
      }
      .dropdown-country{
        .dropdown-menu.show{
          display: block;
          max-height: 250px;
          overflow-y: scroll;
        }
      }
      
    
      .btn-primary.disabled,
      .btn-primary:disabled {
        color: #333 !important;
        background-color: transparent !important;
        border-color: none;
      }
    
    }